import {MESSAGES} from "constants/MESSAGES";
import {LINKLY_RES_STATUS} from "constants/LINKLY_RESPONSES";
import moment from "moment";

const {
    PRESS_OKAY_TO_CONTINIE,
    SWIPE_CARD,
    TRANSECTION_DECLINED,
    PINPAD_BUSY,
    ENTER_ACCOUNT,
    PRESENT,
    PROCESSING,
    PINPAD_OFFLINE,
    PURCHASING,
    ENTER_PIN,
    SIGNATURE,
    REJECT_PURCHASE,
    REJECT_REFUND,
} = LINKLY_RES_STATUS;

export const getCart = () => {
    if (window.localStorage.getItem("cart") === null) {
        return false;
    } else {
        const cart = window.localStorage.getItem("cart");
        return JSON.parse(cart);
    }
};


/** Find is product listred in cart.?
 * @param {Array} cart;
 * @param {Number} productId;
 * @returns boolean;
 */
export const isProductInCart = (cart, productId) => {
    const {items} = cart;
    return items.find(({product: {_id}}) => {
        return _id === productId;
    });
};

export const not = (value) => !value;

export function isFloat(value) {
    return Number(value) === value && value % 1 !== 0;
}

export const isObj = (value) => typeof value === "object";
export const isArray = (value) => Array.isArray(value);

export const is = (value) => {
    if (value) {
        return ((typeof value === "string" && value !== "NA" && value !== "false" && value !== "FALSE" && value !== "null") || (isArray(value) && value.length) || (isObj(value) && Object.keys(value).length));
    }
    return false;
};

export const keysOf = (value) => (isObj(value) && Object.keys(value)) || [];

export const contains = (obj = {}, item = "") => obj[item] || false;

export const log = (value = "", msg = "By log util") => {
    console.group(msg);
    console.log(value);
    console.groupEnd(msg);
};

/** I am using there curring technique
 * @param {number} percent
 * @returns {Function} params -> total
 */
export const calculatePercentage = (percent) => (total) => {
    return (percent / 100) * total;
};

export const applyFixed = (number = 0, fix = 0) => {
    return Number(number).toFixed(fix);
};

export const formatPrice = (price) => {
    if (isFloat(Number(price))) {
        return `$${price}`;
    } else {
        return `$${price}`;
    }
};

// -------------------------------------- //
export function arrayToObj(arr, keyProperty) {
    return arr.reduce((result, item) => {
        if (item.hasOwnProperty(keyProperty)) {
            result[item[keyProperty]] = item;
        }
        return result;
    }, {});
}

/* Example usage:
const arrayOfObjects = [
  { id: "1", name: "John" },
  { id: "2", name: "Jane" },
  { id: "3", name: "Doe" },
];

const keyProperty = "id"; // Specify the property to use as the key

const obj = arrayToObj(arrayOfObjects, keyProperty);

console.log(obj);
*/

/**
 * @param {String} category;
 * @param {Array} categories;
 * @returns {Boolean};
 */
export const isCategoryIncludesIn = (category, categories) => {
    return categories.includes(category);
};

/** Render message
 * @param {string} itemCategory
 * @param {string} type - Info or Warning or Success
 * @returns {string} - Message
 */
export const renderMessage = (type, itemCategory) => {
    return MESSAGES?.[type]?.[itemCategory];
};

const getDisplayMsg = (obj) => {
    return obj?.Response?.DisplayText?.join(" ");
};
const isPinpadBusy = (obj) => {
    return obj?.Response?.ResponseText?.includes("PINpad Busy");
};

/** Collect status of linkly request
 * @param {Object} obj
 */
export const getLinklyResStatus = (obj) => {
    console.log("Bail Task")
    if (not(isPinpadBusy(obj))) {
        let message = getDisplayMsg(obj);
        console.log(message, 'Message')
        if (message?.includes("TAP OK")) {
            return PRESS_OKAY_TO_CONTINIE;
        } else if (message?.includes("SWIPE CARD")) {
            return SWIPE_CARD;
        } else if (message?.includes("TRANSACTION DECLINED")) {
            return TRANSECTION_DECLINED;
        } else if (message?.includes("ENTER ACCOUNT")) {
            return ENTER_ACCOUNT;
        } else if (message?.includes("ENTER PIN")) {
            return ENTER_PIN;
        } else if (message?.includes("SIGNATURE OK?")) {
            return SIGNATURE;
        } else if (message?.includes('PRESENT CARD')) {
            return PRESENT;
        } else if (message?.includes('PROCESSING')) {
            return PROCESSING;
        }
    } else {
        console.log('Pinpad is bussy')
        console.log(obj, 'This is the object in which we can check is that what the response is from linkly.')
        return PINPAD_BUSY;
    }
};

export const isPinpadOnline = (obj) => {
    return not(obj.Response?.ResponseText?.includes("PINpad Offline"));
};

export const isTransectionCompleted = (obj) => {
    return obj?.Response?.Success;
};

/** Increment the Order in this format: Example, 001, 010, 011, 100
 * @param { String } str;
 * @returns { String };
 */
export function incrementString(str) {
    let match = str.match(/\d+$/);

    if (match) {
        let num = parseInt(match[0]);
        num++;
        let newNumericPart = num.toString().padStart(match[0].length, "0");
        let newString = str.replace(/\d+$/, newNumericPart);
        return newString;
    } else {
        return str + "001";
    }
}

/** Save The purchasing status for handling the terminaltion
 * @param {Object} {saving, clearing, sessionID}
 */
export const savePurchasingStatus = ({
                                         saving = false, clearing = false, sessionID = "", currentStatus = "",
                                     }) => {
    if (saving && sessionID) {
        localStorage.setItem("transectionUnderProcess", true);
        localStorage.setItem("sessionID", sessionID);
    } else if (saving == false && clearing) {
        localStorage.removeItem("transectionUnderProcess");
        localStorage.removeItem("sessionID");
    }
};

/** Clear the recover function timers and intervals.
 * @param {Number} timeoutID
 * @param {Number} intervalID
 */
export const clearRecovery = (timeoutID, intervalID) => {
    clearTimeout(timeoutID);
    clearInterval(intervalID);
};

/** Is transection under process
 * If Kiosk distroyed during transection, Check is transection still under process or not.
 * @returns {Boolean}
 */
export const isTransectionUnderProcess = () => {
    const sessionID = localStorage.getItem("sessionID");
    const transectionUnderProcess = localStorage.getItem("transectionUnderProcess");
    if (sessionID && transectionUnderProcess) {
        return true;
    }
};

const ROUTES = {};
/** Navigat to the related screen if any transection in under process after kisok restart.
 * @param {String} status
 * @param {Function} navigate
 */
export const navigateToScreen = (status, navigate) => {
    // navigate(ROUTES?.[states]);
};

/** Handle Actions agains TRANSECTION STATUAS
 * @param {String} status
 */
export const handleStatus = ({
                                 status = "", timeoutID = null, intervalID = null, navigate = () => {
    },
                             }) => {
    switch (status) {
        case TRANSECTION_DECLINED:
            clearRecovery(timeoutID, null);
            navigate("/order_declined");
            clearRecovery(timeoutID, intervalID);
            break;
        default:
            break;
    }
};

/**
 * Creates a function to check whether a given request type is not equal to the current request type.
 *
 * @param {string} currentRequestType - The current request type for comparison.
 * @returns {Function} - A function that takes a request type and returns true if it is not equal to the current request type.
 */
export const isDifferentRequestType = (currentRequestType) => (requestType) => {
    return currentRequestType !== requestType;
};

/**
 * The function `getChildsArray` takes an object as input and returns an array of its child objects.
 * @param childObj - The `childObj` parameter is an object that represents a collection of child
 * objects.
 * @returns an array of values from the `childObj` object.
 */
export const getChildsArray = (childObj) => {
    const childs = childObj;
    const childsObj = childs || {};
    const childsArr = Object.keys(childsObj).map((key) => childsObj[key]) || [];
    return childsArr;
};

// const items = item?.product?.items || item?.items || {};
const processExtras = (extrasList) => {
    let expendingItems = {};
    Object.keys(extrasList).forEach((item) => {
        expendingItems = Object.keys(extrasList[item]).reduce((acc, cur) => {
            return {...acc, [cur]: extrasList[item][cur]};
        }, expendingItems); // Accumulate properties into expendingItems
    });
    return expendingItems;
};

/** Save item extras
 * Extract the items list
 */
export const extractItems = (item) => {
    if (item?.items) {
        const items = item?.product?.items || item?.items || {};
        const itemExtras = processExtras(items);
        return itemExtras;
        // setItemsList(itemExtras);
    }
};

/**
 * The formatCreditCardNumber function takes a credit card number as input and returns a formatted
 * version of the number with asterisks hiding the middle digits, or returns "Invalid card number" if
 * the input is not a valid 16-digit number.
 * @param cardNumber - The `cardNumber` parameter is a string representing a credit card number.
 * @returns either the formatted card number with masked digits or the string "Invalid card number" if
 * the input card number is not valid.
 */
export function formatCardNumber(cardNumber = "") {
    // Remove all whitespaces
    cardNumber = cardNumber.replace(/\s/g, "");

    // Check if the input is a valid card number (only digits)
    if (!/^\d+$/.test(cardNumber)) {
        console.error("Invalid card number. Please provide a valid card number.");
        return null; // or throw an error, depending on your requirements
    }

    // Format the card number
    const length = cardNumber.length;
    const maskedCardNumber = cardNumber.replace(/^(\d{4})(\d+)(\d{4})$/, (match, firstPart, middlePart, lastPart) => {
        const maskedMiddlePart = "*".repeat(length - 8);
        return `${firstPart}${maskedMiddlePart}${lastPart}`;
    });

    return maskedCardNumber;
}

/**
 * The getCurrentDateTime function returns the current date and time in a formatted string.
 * @returns the current date and time in a formatted string.
 */
export function getCurrentDateTime() {
    const now = new Date();

    const options = {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
        hour12: false, // Use 24-hour format
    };

    const formattedDateTime = now.toLocaleString("en-US", options);

    return formattedDateTime;
}


/**
 * Save the App version to the LocalStorage.
 * @param {string} version - upcoming new version.
 * */
export const saveAppVersion = (version) => {
    const currentVersion = localStorage.getItem('currentVersion');
    if (!currentVersion) {
        localStorage.setItem('currentVersion', JSON.stringify(version));
        localStorage.setItem('latestVersion', JSON.stringify(version));
    } else {
        localStorage.setItem('latestVersion', JSON.stringify(version));
    }
}
/**
 * Update the app to lasted version
 * */
export const updateToLatestVersion = (callback = () => {
}) => {
    const currentVersion = JSON.parse(localStorage.getItem('currentVersion') || "{}");
    const latestVersion = JSON.parse(localStorage.getItem('latestVersion') || "{}");

    if (currentVersion && latestVersion && currentVersion !== latestVersion) {
        // Update the app to the latest version
        // Perform any necessary update operations here
        // For example, you might reload the page or execute specific update logic

        // After updating, set the current version to the latest version
        localStorage.setItem('currentVersion', JSON.stringify(latestVersion));
        // Return true to indicate that the update was successful
        callback();
        return true;
    } else {
        // No update needed, or the versions are not set
        return false;
    }
}


/**
 * Check if the current time is inside the specified range.
 * @param {string} startTime - Start time in 'HH:mm' format.
 * @param {string} endTime - End time in 'HH:mm' format.
 * @return {boolean} - True if the current time is between the start and end times, false otherwise.
 */
export const isCurrentTimeInRange = (startTime, endTime) => {
    // Get the current time
    let currentTime = moment();

    // Create moment objects for the start and end times
    let start = moment(startTime, 'HH:mm');
    let end = moment(endTime, 'HH:mm');

    // Return true if the current time is between the start and end times, false otherwise.
    return currentTime.isBetween(start, end);
}


